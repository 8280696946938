// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorPage_mainContainer__qCqaD {
  background-color: #a91c35;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.ErrorPage_errorTitle__LNKBF {
  text-align: center;
  color: white;
}
.ErrorPage_logoAlsina__qYI-R {
  width: 200px;
  height: 55px;
  margin-bottom: 15px;
}
.ErrorPage_errorSubTitle__xA6L5 {
  text-align: center;
  color: white;
  margin-bottom: 50px;
}
.ErrorPage_errorImage__DGx2k {
  width: 300px;
  height: 280px;
  filter: drop-shadow(7px 2px 1px #4d0e19);
}
`, "",{"version":3,"sources":["webpack://./src/pages/errorPage/ErrorPage.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,aAAa;EAEb,wCAAwC;AAC1C","sourcesContent":[".mainContainer {\n  background-color: #a91c35;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n}\n.errorTitle {\n  text-align: center;\n  color: white;\n}\n.logoAlsina {\n  width: 200px;\n  height: 55px;\n  margin-bottom: 15px;\n}\n.errorSubTitle {\n  text-align: center;\n  color: white;\n  margin-bottom: 50px;\n}\n.errorImage {\n  width: 300px;\n  height: 280px;\n  -webkit-filter: drop-shadow(7px 2px 1px #4d0e19);\n  filter: drop-shadow(7px 2px 1px #4d0e19);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `ErrorPage_mainContainer__qCqaD`,
	"errorTitle": `ErrorPage_errorTitle__LNKBF`,
	"logoAlsina": `ErrorPage_logoAlsina__qYI-R`,
	"errorSubTitle": `ErrorPage_errorSubTitle__xA6L5`,
	"errorImage": `ErrorPage_errorImage__DGx2k`
};
export default ___CSS_LOADER_EXPORT___;
