import React from "react";
import styles from "../pages/main/Main.module.css";
import closeIcon from "../assets/closeIcon.png";
import { MapContainer, TileLayer, Circle, Marker, useMap } from "react-leaflet"; // npm install react-leaflet leaflet
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const MapaParadas = (props) => {
  const marker = L.divIcon({
    html: `<div style="background-color: #aa182c; width: 20px; height: 20px; border-radius: 50%; outline: 3px solid white;  -webkit-mask-image: -webkit-radial-gradient(white, black); -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden;"></div>`,
    iconSize: [20, 20],
    className: "",
  });
  const position = [props.latitud, props.longitud]; //substituir esto por los props de latitud y longitud
  const radius = 100;
  console.log(props.paradasRestantes);

  return (
    <div>
      {props.activo ? (
        <div className={styles.overLayProb}>
          <div className={styles.popUpGps}>
            <div className={styles.paradasRestantes}>
              {props.paradasRestantes == 1 ? (
                <>
                  <span>Tu pedido ya está en camino</span>
                </>
              ) : (
                <>
                  <span>Tu pedido está a {props.paradasRestantes} paradas</span>
                </>
              )}

              <img
                src={closeIcon}
                className={styles.closeIconProv}
                onTouchStart={props.funcionMostrarGps}
              />
            </div>
            {/* Por algun motivo, si se le aplica classname en lugar de in-line style en este bloque deja de funcionar  */}
            <div
              style={{
                height: "100%",
                width: "100%",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
              }}
            >
              <MapContainer
                attributionControl={false}
                center={position}
                zoom={16}
                style={{
                  height: "100%",
                  width: "100%",
                  borderBottomLeftRadius: "17px",
                  borderBottomRightRadius: "17px",
                }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={position} icon={marker} />
                <Circle center={position} radius={radius} color="red" />
              </MapContainer>
            </div>
            {/*^^^^^^^^^^^^ Por algun motivo, si se le aplica classname en lugar de in-line style en este bloque deja de funcionar ^^^^^^^^^^^^ */}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default MapaParadas;
