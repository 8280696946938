import React, { useState } from "react";
import styles from "../pages/main/Main.module.css";
import arrowDown from "../assets/arrowDown.png";
import arrowUp from "../assets/arrowUp.png";
import fp from "../assets/fp.png";
import pc from "../assets/pc.png";
import pet from "../assets/pet.png";
import pp from "../assets/pp.png";
import verificado_T from "../assets/verificado_T.png";
import verificado from "../assets/verificado.png";
import no_verificado from "../assets/no_verificado.png";
import ListadoPedido from "./ListadoPedido";
import locationIcon from "../assets/location.png";
import MapaParadas from "./MapaParadas";

const Pedido = (props) => {
  const [showMaterials, setShowMaterials] = useState(false);
  const [showHideList, setShowHideList] = useState(false);
  const [showHideGPSTracking, setShowHideGPSTracking] = useState(false);

  let contentToRenderStatus;

  function showHideGPSLocation() {
    console.log("Hola");
    setShowHideGPSTracking(!showHideGPSTracking);
  }

  function displayList() {
    console.log("Hola");
    setShowMaterials(!showMaterials);
    console.log(showMaterials);
  }

  //////////////////////////////MOSTRAR Y OCULTAR CADA DESPLEGABLE ELEMENTO/////////////////////////////////
  function ShowHideContent(event) {
    let clickedElementChildren = event.target.parentElement.children[1];
    let clickedElementMainNode;

    if (event.target.id === "deliveryTitle") {
      clickedElementChildren = event.target.parentElement.children[1];
      clickedElementMainNode = event.target;
    } else {
      clickedElementChildren =
        event.target.parentElement.parentElement.children[1];
      clickedElementMainNode = event.target.parentElement;
    }

    console.log(event.target.id);
    console.log(clickedElementChildren);
    console.log(clickedElementMainNode);
    if (
      event.target.id != "carrierContainer" &&
      event.target.id != "carrierContainerBrand" &&
      event.target.id != "carrierContainerIcon"
    ) {
      if (showHideList) {
        clickedElementChildren.style.display = "none";
        clickedElementMainNode.children[3].src = arrowDown;
        clickedElementMainNode.style.borderBottomLeftRadius = "8px";
        clickedElementMainNode.style.borderBottomRightRadius = "8px";
      } else {
        clickedElementChildren.style.display = "block";
        clickedElementMainNode.children[3].src = arrowUp;
        clickedElementMainNode.style.borderBottomLeftRadius = "0px";
        clickedElementMainNode.style.borderBottomRightRadius = "0px";
      }
      setShowHideList(!showHideList);
    }
  }
  ////////////////////////////////////////////SWITCH QUE CONTROLA QUE ELEMENTO CARGAR EN FUNCION DEL PROP/////////////////////////////////////////////////
  switch (props.status) {
    case "1":
      contentToRenderStatus = (
        <div className={styles.deliveryContent} id="content">
          <div className={`${styles.contentRow} ${styles.firstRow}`}>
            <div className={`${styles.iconHolder} ${styles.activeIcon}`}>
              <img className={styles.stepIcon} src={pp} />
            </div>
            <span className={styles.contentText}>Pedido aprobado</span>
            <div className={`${styles.falseButton} ${styles.activeButton}`}>
              <span
                className={styles.contentText}
                onClick={() => displayList()}
              >
                Ver Listado
              </span>
            </div>
          </div>
          <div className={`${styles.contentRow} ${styles.mr}`}>
            <div className={`${styles.iconHolder} ${styles.disabledIcon}`}>
              <img className={styles.stepIcon} src={fp} />
            </div>
            <span className={styles.contentText}>Fecha planificada:</span>
            <div className={`${styles.falseButton} ${styles.disabledButton}`}>
              <span
                className={`${styles.contentText} ${styles.alsinaDeliverer}`}
              >
                <span>Pendiente</span>
              </span>
            </div>
          </div>
          <div className={`${styles.contentRow} ${styles.mr}`}>
            <div className={`${styles.iconHolder} ${styles.disabledIcon}`}>
              <img className={styles.stepIcon} src={pc} />
            </div>
            <span className={styles.contentText}>Pedido en camino</span>
            {props.delivery === "propio" ? (
              <div className={`${styles.falseButton} ${styles.disabledButton}`}>
                <span className={styles.contentText}>Ver ubicación</span>
              </div>
            ) : (
              <div
                className={`${styles.falseButton} ${styles.noButtonGeneric}`}
              >
                <span className={styles.contentText}>Ver ubicación</span>
              </div>
            )}
          </div>
          <div
            className={`${styles.contentRow} ${styles.mr} ${styles.lastPhase}`}
          >
            <div className={`${styles.iconHolder} ${styles.disabledIcon}`}>
              <img className={styles.stepIcon} src={pet} />
            </div>
            {props.delivery === "propio" ? (
              <span className={styles.contentText}>
                Pedido entregado al cliente
              </span>
            ) : (
              <span className={styles.contentText}>
                Pedido entregado al transportista
              </span>
            )}
            {props.delivery === "propio" ? (
              <div className={`${styles.falseButton} ${styles.noButtonOur}`}>
                <span className={styles.contentText}>a</span>
              </div>
            ) : (
              <div className={`${styles.falseButton} ${styles.noButton}`}>
                <span className={styles.contentText}>a</span>
              </div>
            )}
          </div>
        </div>
      );

      break;
    case "2":
      contentToRenderStatus = (
        <div className={styles.deliveryContent} id="content">
          <div className={`${styles.contentRow} ${styles.firstRow}`}>
            <div className={`${styles.iconHolder} ${styles.activeIcon}`}>
              <img className={styles.stepIcon} src={pp} />
            </div>
            <span className={styles.contentText}>Pedido aprobado</span>
            <div className={`${styles.falseButton} ${styles.activeButton}`}>
              <span
                className={styles.contentText}
                onClick={() => displayList()}
              >
                Ver Listado
              </span>
            </div>
          </div>
          <div className={`${styles.contentRow} ${styles.mr}`}>
            <div className={`${styles.iconHolder} ${styles.activeIcon}`}>
              <img className={styles.stepIcon} src={fp} />
            </div>
            <span className={styles.contentText}>Fecha planificada:</span>
            <div className={`${styles.falseButton} ${styles.activeButton}`}>
              <span
                className={`${styles.contentText} ${styles.alsinaDeliverer}`}
              >
                {props.fechaPlanificada}{" "}
                {/* {props.delivery === "propio" ? (
                  <span>{props.horaPlanificada}</span>
                ) : (
                  <div></div>
                )} */}
              </span>
            </div>
          </div>
          <div className={`${styles.contentRow} ${styles.mr}`}>
            <div className={`${styles.iconHolder} ${styles.disabledIcon}`}>
              <img className={styles.stepIcon} src={pc} />
            </div>
            <span className={styles.contentText}>Pedido en camino</span>
            {props.delivery === "propio" ? (
              <div className={`${styles.falseButton} ${styles.disabledButton}`}>
                <span className={styles.contentText}>Ver ubicación</span>
              </div>
            ) : (
              <div
                className={`${styles.falseButton} ${styles.noButtonGeneric}`}
              >
                <span className={styles.contentText}>Ver ubicación</span>
              </div>
            )}
          </div>
          <div
            className={`${styles.contentRow} ${styles.mr} ${styles.lastPhase}`}
          >
            <div className={`${styles.iconHolder} ${styles.disabledIcon}`}>
              <img className={styles.stepIcon} src={pet} />
            </div>
            {props.delivery === "propio" ? (
              <span className={styles.contentText}>
                Pedido entregado al cliente
              </span>
            ) : (
              <span className={styles.contentText}>
                Pedido entregado al transportista
              </span>
            )}
            {props.delivery === "propio" ? (
              <div className={`${styles.falseButton} ${styles.noButtonOur}`}>
                <span className={styles.contentText}>a</span>
              </div>
            ) : (
              <div className={`${styles.falseButton} ${styles.noButton}`}>
                <span className={styles.contentText}>a</span>
              </div>
            )}
          </div>
        </div>
      );
      break;
    case "3":
      contentToRenderStatus = (
        <div className={styles.deliveryContent} id="content">
          <div className={`${styles.contentRow} ${styles.firstRow}`}>
            <div className={`${styles.iconHolder} ${styles.activeIcon}`}>
              <img className={styles.stepIcon} src={pp} />
            </div>
            <span className={styles.contentText}>Pedido aprobado</span>
            <div className={`${styles.falseButton} ${styles.activeButton}`}>
              <span
                className={styles.contentText}
                onClick={() => displayList()}
              >
                Ver Listado
              </span>
            </div>
          </div>
          <div className={`${styles.contentRow} ${styles.mr}`}>
            <div className={`${styles.iconHolder} ${styles.activeIcon}`}>
              <img className={styles.stepIcon} src={fp} />
            </div>
            <span className={styles.contentText}>Fecha planificada:</span>
            <div className={`${styles.falseButton} ${styles.activeButton}`}>
              <span
                className={`${styles.contentText} ${styles.alsinaDeliverer}`}
              >
                {props.fechaPlanificada}{" "}
                {/* {props.delivery === "propio" ? (
                  <span>{props.horaPlanificada}</span>
                ) : (
                  <div></div>
                )} */}
              </span>
            </div>
          </div>
          <div className={`${styles.contentRow} ${styles.mr}`}>
            <div className={`${styles.iconHolder} ${styles.activeIcon}`}>
              <img className={styles.stepIcon} src={pc} />
            </div>
            <span className={styles.contentText}>Pedido en camino</span>

            {props.delivery == "propio" ? (
              <div
                onTouchStart={showHideGPSLocation}
                className={`${styles.falseButton} ${styles.activeButton}`}
              >
                {/* el evento on click de arriba es provisional al igual que el metodo, BORRAR AL ACABAR  */}
                <span className={styles.contentText}>
                  {props.horaPlanificada}
                </span>
                <img src={locationIcon} className={styles.locationIcon} />
              </div>
            ) : (
              <div
                className={`${styles.falseButton} ${styles.noButtonGeneric}`}
              >
                <span className={styles.contentText}>
                  {props.horaPlanificada}
                </span>
                <img src={locationIcon} className={styles.locationIcon} />
              </div>
            )}
            {/* <span className={styles.contentText}>Ver ubicación</span> */}
          </div>
          <div
            className={`${styles.contentRow} ${styles.mr} ${styles.lastPhase}`}
          >
            <div className={`${styles.iconHolder} ${styles.disabledIcon}`}>
              <img className={styles.stepIcon} src={pet} />
            </div>
            {props.delivery === "propio" ? (
              <span className={styles.contentText}>
                Pedido entregado al cliente
              </span>
            ) : (
              <span className={styles.contentText}>
                Pedido entregado al transportista
              </span>
            )}
            {props.delivery === "propio" ? (
              <div className={`${styles.falseButton} ${styles.noButtonOur}`}>
                <span className={styles.contentText}>a</span>
              </div>
            ) : (
              <div className={`${styles.falseButton} ${styles.noButton}`}>
                <span className={styles.contentText}>a</span>
              </div>
            )}
          </div>
        </div>
      );
      break;
    case "4":
      contentToRenderStatus = (
        <div className={styles.deliveryContent} id="content">
          <div className={`${styles.contentRow} ${styles.firstRow}`}>
            <div className={`${styles.iconHolder} ${styles.activeIcon}`}>
              <img className={styles.stepIcon} src={pp} />
            </div>
            <span className={styles.contentText}>Pedido aprobado</span>
            <div className={`${styles.falseButton} ${styles.activeButton}`}>
              <span
                className={styles.contentText}
                onClick={() => displayList()}
              >
                Ver Listado
              </span>
            </div>
          </div>
          <div className={`${styles.contentRow} ${styles.mr}`}>
            <div className={`${styles.iconHolder} ${styles.activeIcon}`}>
              <img className={styles.stepIcon} src={fp} />
            </div>
            <span className={styles.contentText}>Fecha planificada:</span>
            <div className={`${styles.falseButton} ${styles.activeButton}`}>
              <span
                className={`${styles.contentText} ${styles.alsinaDeliverer}`}
              >
                {props.fechaPlanificada}{" "}
                {/* {props.delivery === "propio" ? (
                  <span>{props.horaPlanificada}</span>
                ) : (
                  <div></div>
                )} */}
              </span>
            </div>
          </div>
          <div className={`${styles.contentRow} ${styles.mr}`}>
            <div className={`${styles.iconHolder} ${styles.activeIcon}`}>
              <img className={styles.stepIcon} src={pc} />
            </div>
            <span className={styles.contentText}>Pedido en camino</span>
            {props.delivery == "propio" ? (
              <div className={`${styles.falseButton} ${styles.activeButton}`}>
                <span className={styles.contentText}>
                  {props.horaPlanificada}
                </span>
                {/* <img src={locationIcon} className={styles.locationIcon} /> */}
              </div>
            ) : (
              <div
                className={`${styles.falseButton} ${styles.noButtonGeneric}`}
              >
                <span className={styles.contentText}>
                  {props.horaPlanificada}
                </span>
                <img src={locationIcon} className={styles.locationIcon} />
              </div>
            )}
          </div>
          <div
            className={`${styles.contentRow} ${styles.mr} ${styles.lastPhase}`}
          >
            <div className={`${styles.iconHolder} ${styles.activeIcon}`}>
              <img className={styles.stepIcon} src={pet} />
            </div>
            {props.delivery === "propio" ? (
              <span className={styles.contentText}>
                Pedido entregado al cliente
              </span>
            ) : (
              <span className={styles.contentText}>
                Pedido entregado al transportista
              </span>
            )}
            {props.delivery === "propio" ? (
              <div className={`${styles.falseButton} ${styles.noButtonOur}`}>
                <span className={styles.contentText}>a</span>
              </div>
            ) : (
              <div className={`${styles.falseButton} ${styles.noButton}`}>
                <span className={styles.contentText}>a</span>
              </div>
            )}
          </div>
        </div>
      );
      break;

    default:
      break;
  }

  return (
    ///////////////////////// ENCABEZADO DE CADA PEDIDO Y SEPARA MEDIANTE PROPS SI ES O NO UN TRANSPORTISTA DE ALSINA//////////////////////////////////
    <div>
      {/* ////////////////////AQUI SE CARGARIAN EL LISTADO DE MATERIALES/////////////////////// */}
      <ListadoPedido
        mostrable={showMaterials}
        funcionMostrar={displayList}
        datosListaMaterialesLast={props.datosListaMateriales}
      />
      <MapaParadas
        activo={showHideGPSTracking}
        funcionMostrarGps={showHideGPSLocation}
        latitud={props.latitud_transportista}
        longitud={props.longitud_transportista}
        paradasRestantes={props.paradas}
      />
      {/* ////////////////////////////////////////////////////////////////////////////////////// */}
      <div id="deliveryContainerWrapper">
        <div className={styles.delivery}>
          <div
            id="deliveryTitle"
            className={styles.deliveryTitle}
            onClick={ShowHideContent}
          >
            <span className={styles.deliveryNumber}>
              Pedido {props.numeroPedido}
            </span>
            <div id="carrierContainer" className={styles.carrierContainer}>
              {props.delivery === "propio" ? (
                <div id="carrierContainer">
                  {" "}
                  <img
                    id="carrierContainerBrand"
                    className={styles.brand}
                    src={verificado}
                  />
                  <img
                    id="carrierContainerIcon"
                    className={styles.carrierIcon}
                    src={verificado_T}
                  />
                </div>
              ) : (
                <div id="carrierContainer">
                  <img
                    id="carrierContainerBrand"
                    className={styles.brand}
                    src={no_verificado}
                  />
                </div>
              )}
            </div>
            <span className={styles.datePlacement}>{props.fechaPedido}</span>
            <img className={styles.arrowIcon} id="arrow" src={arrowDown} />
          </div>
          {/* <!-- contenido del desplegable del pedido --> */}
          {contentToRenderStatus}
        </div>
      </div>
    </div>
  );
};

export default Pedido;
