import React from "react";
import styles from "../orderList/orderList.module.css";
import logo_aslina from "../assets/logo_alsina.png";

const OrderListComp = () => {
  function closeAndRedirect() {
    var currentURL = window.location.href;
    var newURL = currentURL.replace(/\/[^\/]*$/, "/od");
    window.location.href = newURL;
  }

  function openPopUp() {
    document.getElementById("PopUp").style.display = "flex";
  }

  return (
    <div>
      <nav className={styles.navBar}>
        <img src={logo_aslina} className={styles.logoAlsina} />
      </nav>
      <div className={styles.mainContainer}>
        <h2 className={styles.pageTitle}>Pedidos disponibles</h2>
        <div className={styles.deliveryContainers}>
          <div className={styles.headers}>
            <span>Seleccionar</span>
            <span className={styles.pedidoHeader}>Pedido</span>
            <span className={styles.entregaHeader}>Entrega</span>
            <span className={styles.clienteHeader}>Cliente</span>
          </div>

          <div className={styles.orderList}>
            {/* <!-- PEDIDO  --> */}
            <div className={styles.orderContainer}>
              <div className={styles.order}>
                <div>
                  <label className={styles.switch}>
                    <input type="checkbox" />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                  </label>
                </div>
                <div className={styles.labelContainer}>
                  <span>951239</span>
                </div>
                <div className={styles.labelContainer}>
                  <span>10/06</span>
                </div>
                <div
                  className={`${styles.labelContainer} ${styles.clientName}`}
                >
                  <span>Construmax</span>
                </div>
              </div>
            </div>
            {/* <!-- PEDIDO  --> */}
            {/* <!-- PEDIDO  --> */}
            <div className={styles.orderContainer}>
              <div className={styles.order}>
                <div>
                  <label className={styles.switch}>
                    <input type="checkbox" />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                  </label>
                </div>
                <div className={styles.labelContainer}>
                  <span>951240</span>
                </div>
                <div className={styles.labelContainer}>
                  <span>11/06</span>
                </div>
                <div
                  className={`${styles.labelContainer} ${styles.clientName}`}
                >
                  <span>Buildex</span>
                </div>
              </div>
            </div>
            {/* <!-- PEDIDO  --> */}
            {/* <!-- PEDIDO  --> */}
            <div className={styles.orderContainer}>
              <div className={styles.order}>
                <div>
                  <label className={styles.switch}>
                    <input type="checkbox" />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                  </label>
                </div>
                <div className={styles.labelContainer}>
                  <span>951241</span>
                </div>
                <div className={styles.labelContainer}>
                  <span>13/06</span>
                </div>
                <div
                  className={`${styles.labelContainer} ${styles.clientName}`}
                >
                  <span>Infraurb</span>
                </div>
              </div>
            </div>
            {/* <!-- PEDIDO  --> */}
            {/* <!-- PEDIDO  --> */}
            <div className={styles.orderContainer}>
              <div className={styles.order}>
                <div>
                  <label className={styles.switch}>
                    <input type="checkbox" />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                  </label>
                </div>
                <div className={styles.labelContainer}>
                  <span>951242</span>
                </div>
                <div className={styles.labelContainer}>
                  <span>17/06</span>
                </div>
                <div
                  className={`${styles.labelContainer} ${styles.clientName}`}
                >
                  <span>Urbanix</span>
                </div>
              </div>
            </div>
            {/* <!-- PEDIDO  --> */}
            {/* <!-- PEDIDO  --> */}
            <div className={styles.orderContainer}>
              <div className={styles.order}>
                <div>
                  <label className={styles.switch}>
                    <input type="checkbox" />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                  </label>
                </div>
                <div className={styles.labelContainer}>
                  <span>951246</span>
                </div>
                <div className={styles.labelContainer}>
                  <span>19/06</span>
                </div>
                <div
                  className={`${styles.labelContainer} ${styles.clientName}`}
                >
                  <span>Solidez</span>
                </div>
              </div>
            </div>
            {/* <!-- PEDIDO  --> */}
            {/* <!-- PEDIDO  --> */}
            <div className={styles.orderContainer}>
              <div className={styles.order}>
                <div>
                  <label className={styles.switch}>
                    <input type="checkbox" />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                  </label>
                </div>
                <div className={styles.labelContainer}>
                  <span>951250</span>
                </div>
                <div className={styles.labelContainer}>
                  <span>21/06</span>
                </div>
                <div
                  className={`${styles.labelContainer} ${styles.clientName}`}
                >
                  <span>Estructur</span>
                </div>
              </div>
            </div>
            {/* <!-- PEDIDO  --> */}
            {/* <!-- PEDIDO  --> */}
            <div className={styles.orderContainer}>
              <div className={styles.order}>
                <div>
                  <label className={styles.switch}>
                    <input type="checkbox" />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                  </label>
                </div>
                <div className={styles.labelContainer}>
                  <span>951251</span>
                </div>
                <div className={styles.labelContainer}>
                  <span>23/06</span>
                </div>
                <div
                  className={`${styles.labelContainer} ${styles.clientName}`}
                >
                  <span>Edifix</span>
                </div>
              </div>
            </div>
            {/* <!-- PEDIDO  --> */}
            {/* <!-- PEDIDO  --> */}
            <div className={styles.orderContainer}>
              <div className={styles.order}>
                <div>
                  <label className={styles.switch}>
                    <input type="checkbox" />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                  </label>
                </div>
                <div className={styles.labelContainer}>
                  <span>951253</span>
                </div>
                <div className={styles.labelContainer}>
                  <span>25/06</span>
                </div>
                <div
                  className={`${styles.labelContainer} ${styles.clientName}`}
                >
                  <span>Construmax</span>
                </div>
              </div>
            </div>
            {/* <!-- PEDIDO  --> */}
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.genericButton} onClick={openPopUp}>
              Asignar Pedidos
            </button>
          </div>
        </div>
      </div>
      {/* <!-- popUp  --> */}
      <div
        id="PopUp"
        className={`${styles.mainContainer} ${styles.msgOverlay}`}
      >
        <div className={styles.msgPopUp}>
          <div className={styles.popUpContent}>
            <span>Pedidos Asignados Correctamente</span>

            <button
              className={`${styles.genericButton} ${styles.aceptButton}`}
              onClick={closeAndRedirect}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderListComp;
