import React, { useEffect } from "react";
import axios from "axios";

let queryResponse;
const TestSQL = () => {
  // Función para ejecutar la consulta SQL
  const ejecutarConsultaSQL = async (valorParaQuery) => {
    try {
      const response = await axios.get(
        `http://localhost:3001/consultar-pedidos/${valorParaQuery}`
      );
      queryResponse = response.data;
      postProcesado(queryResponse);
    } catch (error) {
      console.error("Error al ejecutar la consulta en el frontEnd:", error);
    }
  };

  useEffect(() => {
    let valorParaQuery = 1002;
    ejecutarConsultaSQL(valorParaQuery);
  }, []);

  //////////////////Metodo encargado del Post Procesado de los valores//////////////////
  const postProcesado = (valores) => {
    console.log("Diccionario original");
    console.log(valores);
    //Eliminar informacion irrelevante
    const fechaReducida = valores.map(
      ({ fecha_realizacion, fecha_prevista, ...resto }) => ({
        ...resto,
        fecha_realizacion: fecha_realizacion.split("T")[0],
        fecha_prevista: fecha_prevista ? fecha_prevista.split("T")[0] : null,
      })
    );
    console.log("Fecha sin la morralla");
    console.log(fechaReducida);

    const fechaReducidaOrdenada = fechaReducida
      .map(({ fecha_realizacion, fecha_prevista, ...resto }) => ({
        ...resto,
        fecha_realizacion: fecha_realizacion
          ? formatDate(fecha_realizacion)
          : null,
        fecha_prevista: fecha_prevista ? formatDate(fecha_prevista) : null,
      }))
      .sort((a, b) => {
        return new Date(a.fecha_realizacion) - new Date(b.fecha_realizacion);
      });
    console.log("Fecha bien formateada: ");
    console.log(fechaReducidaOrdenada);

    //Agrupar por pedidos//
    const datosAgrupados = fechaReducidaOrdenada.reduce(
      (acumulador, elemento) => {
        const numeroPedido = elemento.numero_pedido;
        if (!acumulador[numeroPedido]) {
          acumulador[numeroPedido] = [];
        }
        acumulador[numeroPedido].push(elemento);
        return acumulador;
      },
      {}
    );
    console.log("Agrupados por numero de pedido");
    console.log(datosAgrupados);

    //Unificar materiales pedidos
    const combinado = Object.values(datosAgrupados).map((array) => {
      return array.reduce((acumulador, objeto) => {
        const { codigo_producto, cantidad, descripcion_producto, ...resto } =
          objeto;
        if (!acumulador) {
          return {
            ...resto,
            listado: [{ codigo_producto, cantidad, descripcion_producto }],
          };
        }
        acumulador.listado.push({
          codigo_producto,
          cantidad,
          descripcion_producto,
        });
        return acumulador;
      }, null);
    });
    console.log("Resultado final");
    console.log(combinado);
  };
  function formatDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }
  return <div></div>;
};

export default TestSQL;
