import React, { useEffect } from "react";
import closeIcon from "../assets/closeIcon.png";
import styles from "../pages/main/Main.module.css";
const ListadoPedido = (props) => {
  // props.datosListaMaterialesLast contiene la informacion del listado de los materiales
  return (
    <div>
      {props.mostrable === true ? (
        <div id="materialPopUp" className={styles.popUpBackground}>
          <div className={styles.popUpListMaterials}>
            <div className={styles.PopUpHeader}>
              <span>Código | Descripción | Cantidad</span>
              <img
                className={styles.closePopUp}
                src={closeIcon}
                onClick={props.funcionMostrar}
              />
            </div>
            <table>
              <tbody>
                {/* /////////////////////////Generar la lista de forma dinamica////////////////////////////// */}
                {props.datosListaMaterialesLast.map((material, index) => (
                  <tr key={index}>
                    <td>{material.codigo_producto}</td>
                    <td>{material.descripcion_producto}</td>
                    <td>{material.cantidad_producto}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={styles.anotation}>
              <span>
                Advertencia: El listado de material es orientativo y puede
                variar respecto la entrega final por motivos de disponibilidad y
                embalaje.
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ListadoPedido;
