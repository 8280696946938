import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; //npm install react-beautiful-dnd
import styles from "../orderDeliveryDetails/orderDeliveryDetails.module.css";
import logo_alsina from "../assets/logo_alsina.png";
import truck from "../assets/truck-fast-solid.png";
import location from "../assets/localizacion.png";
import trash from "../assets/trash-can-solid.png";
import sort from "../assets/sort-solid.png";
import closeIcon from "../assets/closeIcon.png";

const OrderDeliveryDetailsComp = () => {
  const [orders, setOrders] = useState([
    {
      id: "1",
      number: 951239,
      client: "Construmax",
      address: "Calle del Portal Nou, 26, 17004 Girona",
    },
    {
      id: "2",
      number: 951240,
      client: "Buildex",
      address: "Calle Carme Amaya,1, 08120 La Llagosta",
    },
  ]);

  function openPasswordPopUp() {
    document.getElementById("PopUpNoDelivery").style.display = "flex";
  }
  function closePopUp() {
    document.getElementById("PopUpNoDelivery").style.display = "none";
  }
  function redirect() {
    var currentURL = window.location.href;
    var newURL = currentURL.replace(/\/[^\/]*$/, "/ol");
    window.location.href = newURL;
  }

  function confirmDelivery() {
    document.getElementById("popUpConfirmarEntrega").style.display = "flex";
  }
  function closeDelivery() {
    document.getElementById("popUpConfirmarEntrega").style.display = "none";
  }
  function confirmDelete() {
    document.getElementById("popUpDelete").style.display = "flex";
  }
  function closeDelete() {
    document.getElementById("popUpDelete").style.display = "none";
  }
  function openRoute(event) {
    console.log(event.target.innerHTML);
    var linkElement = event.target.innerHTML;
    var googleMapsUrl =
      "https://www.google.com/maps/dir/?api=1&destination=" +
      encodeURIComponent(linkElement);
    window.open(googleMapsUrl, "_blank");
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(orders);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setOrders(items);
  }

  return (
    <div>
      <nav className={styles.navBar}>
        <img src={logo_alsina} className={styles.logoAlsina} />
      </nav>
      <div className={styles.mainContainer}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="orders">
            {(provided) => (
              <div
                className={styles.deliveryContainers}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {orders.map(({ id, number, client, address }, index) => (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className={`${styles.ordersContainers} ${
                          snapshot.isDragging ? styles.dragging : ""
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className={styles.activeOrder}>
                          <span className={styles.orderNumber}>
                            Pedido: {number}
                          </span>
                          <span>Cliente: {client}</span>
                          <div className={styles.adressAndIconContainer}>
                            <img
                              className={styles.locationIcon}
                              src={location}
                              height="20px"
                              width="20px"
                            />
                            <a onClick={openRoute} className={styles.adress}>
                              {address}
                            </a>
                          </div>
                        </div>
                        <div className={styles.interactiveSecction}>
                          <button
                            className={`${styles.genericButton} ${styles.confirmDelivery}`}
                            onClick={confirmDelivery}
                          >
                            Confirmar Entrega
                          </button>
                          <div className={styles.deleteButtonContainer}>
                            <img
                              onClick={confirmDelete}
                              src={trash}
                              width="10px"
                              height="10px"
                            />
                          </div>
                        </div>
                        <div className={styles.aditionalContent}>
                          <div className={styles.sortingNumber}>
                            <span>{index + 1}</span>
                          </div>
                          <img src={sort} />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}

                {/* No Delivery Yet */}

                <div className={styles.noDeliveryYet}>
                  <span>¿Te estas quedando sin pedidos?</span>
                  <img className={styles.trackStyles} src={truck} />
                  <button
                    className={styles.genericButton}
                    onClick={openPasswordPopUp}
                  >
                    Solicitar Pedidos
                  </button>
                </div>

                {/* Delivered Orders */}
                <div
                  className={styles.ordersContainers}
                  style={{ pointerEvents: "none" }}
                >
                  <div
                    className={`${styles.activeOrder} ${styles.deliveredDetails}`}
                  >
                    <span className={styles.orderNumber}>Pedido: 951235</span>
                    <span>Cliente: Estructur</span>
                    <div className={styles.adressAndIconContainer}>
                      <a className={styles.adress}>
                        Calle Paraiso 16, 08019, Barcelona
                      </a>
                    </div>
                  </div>
                  <div className={styles.interactiveSecction}>
                    <button
                      className={`${styles.genericButton} ${styles.deliveredButton}`}
                    >
                      Entregado
                    </button>
                  </div>
                  <div className={styles.aditionalContent}></div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div
        id="PopUpNoDelivery"
        className={`${styles.mainContainer} ${styles.overlay}`}
      >
        <div className={styles.popUp}>
          <div className={styles.closeContainer}>
            <img
              className={styles.closeIconSTL}
              onClick={closePopUp}
              src={closeIcon}
            />
          </div>
          <div className={styles.popUpContent}>
            <span>Introduce la contraseña del almacén</span>
            <input
              className={styles.passwordInput}
              placeholder="Para esto, deberás entregar tu dispositivo al operario"
            />
            <button
              className={`${styles.genericButton} ${styles.aceptButton}`}
              onClick={redirect}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>

      <div
        id="popUpConfirmarEntrega"
        className={`${styles.mainContainer} ${styles.overlay}`}
      >
        <div className={styles.popUp}>
          <div className={styles.closeContainer}>
            <img
              className={styles.closeIconSTL}
              onClick={closeDelivery}
              src={closeIcon}
            />
          </div>
          <div className={`${styles.popUpContent} ${styles.confirmContent}`}>
            <span className={styles.confirmText}>
              ¿Confirmar la entrega del pedido número 951239 en Calle del Portal
              Nou, 26, 17004 Girona?
            </span>
            <button
              className={`${styles.genericButton} ${styles.confirmButton}`}
              onClick={closeDelivery}
            >
              Confirmar Entrega
            </button>
          </div>
        </div>
      </div>

      <div
        id="popUpDelete"
        className={`${styles.mainContainer} ${styles.overlay}`}
      >
        <div className={styles.popUp}>
          <div className={styles.closeContainer}>
            <img
              className={styles.closeIconSTL}
              onClick={closeDelete}
              src={closeIcon}
            />
          </div>
          <div className={`${styles.popUpContent} ${styles.confirmContent}`}>
            <span className={styles.confirmText}>
              ¿Estas seguro de que quieres cancelar la entrega del pedido
              951239?
            </span>
            <button
              className={`${styles.genericButton} ${styles.confirmButton}`}
              onClick={closeDelete}
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDeliveryDetailsComp;
