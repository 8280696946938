import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
///Deshabilitar los webSocket para que no molesten
if (window.WebSocket) {
  window.WebSocket = function () {
    console.log("Los websockets han sido deshabilitados globalmente");
  };
}
