import React from "react";
import styles from "../errorPage/ErrorPage.module.css";
import errorImg from "../../assets/crash.png";
import logoAlsina from "../../assets/logo_alsina.png";
const ErrorPage = () => {
  function returnToLanding() {
    var currentURL = window.location.href;
    var newURL = currentURL.replace(/\/[^\/]*$/, "/landing");
    window.location.href = newURL;
  }
  return (
    <div className={styles.mainContainer}>
      <img
        title="Volver a la pagina de inicio"
        src={logoAlsina}
        onClick={returnToLanding}
        className={styles.logoAlsina}
      />
      <h2 className={styles.errorTitle}>
        La página que buscas no está disponible.
      </h2>
      <span className={styles.errorSubTitle}>
        Por favor, verifica la URL e intentalo nuevamente.
      </span>
      <img src={errorImg} className={styles.errorImage} />
    </div>
  );
};

export default ErrorPage;
