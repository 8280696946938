// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landscape-warning {
  width: 100vw;
  height: 100vh;
  background-color: #a91c35;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
}
.landscape-warning > p {
  color: white;
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,qBAAqB;EACrB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,MAAM;EACN,OAAO;AACT;AACA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".landscape-warning {\n  width: 100vw;\n  height: 100vh;\n  background-color: #a91c35;\n  display: flex;\n  align-content: center;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n}\n.landscape-warning > p {\n  color: white;\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
