import React from "react";
import styles from "../firstScreen/firstScreen.module.css";
import logo_alsina from "../assets/logo_alsina.png";
import truck from "../assets/truck-fast-solid.png";
import closeIcon from "../assets/closeIcon.png";

const FirstScreenComp = () => {
  function openPasswordPopUp() {
    document.getElementById("PopUp").style.display = "flex";
  }
  function closePopUp() {
    document.getElementById("PopUp").style.display = "none";
  }
  function redirect() {
    var currentURL = window.location.href;
    var newURL = currentURL.replace(/\/[^\/]*$/, "/ol");
    window.location.href = newURL;
  }

  return (
    <div>
      <nav className={styles.navBar}>
        <img src={logo_alsina} className={styles.logoAlsina} />
      </nav>
      <div className={styles.mainContainer}>
        <div className={styles.deliveryContainers}>
          <div className={styles.noDeliveryYet}>
            <span>Aún No Tienes Pedidos Por Entregar</span>
            <img className={styles.trackStyles} src={truck} />
            <button
              className={styles.genericButton}
              onClick={openPasswordPopUp}
            >
              Solicitar Pedidos
            </button>
          </div>
        </div>
      </div>
      <div
        id="PopUp"
        className={`${styles.mainContainer} ${styles.passwordOverlay}`}
      >
        <div className={styles.passwordPopUp}>
          <div className={styles.closeContainer}>
            <img
              className={styles.closeIcon}
              onClick={closePopUp}
              src={closeIcon}
            />
          </div>
          <div className={styles.popUpContent}>
            <span>Introduce la contraseña del almacén</span>
            <input
              className={styles.passwordInput}
              placeholder="Para esto, deberás entregar tu dispositivo al operario"
            />
            <button
              className={`${styles.genericButton} ${styles.aceptButton}`}
              onClick={redirect}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstScreenComp;
