import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Landing from "../pages/landing/Landing";
import Main from "../pages/main/Main";
import Encriptor from "../pages/easyEncript/Encriptor";
import ErrorPage from "../pages/errorPage/ErrorPage";
import FirstScreenComp from "../borrarAlAcabar/firstScreen/firstScreenComp";
import OrderListComp from "../borrarAlAcabar/orderList/orderListComp";
import OrderDeliveryDetailsComp from "../borrarAlAcabar/orderDeliveryDetails/orderDeliveryDetailsComp";
export const MainRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/landing" />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/main" element={<Main />} />
        <Route path="/earc" element={<Encriptor />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="*" element={<Navigate to="/error" />} />
        {/* ///////////////////////borrar al acabar//////////////////  */}
        <Route path="/fs" element={<FirstScreenComp />} />
        <Route path="/ol" element={<OrderListComp />} />
        <Route path="/od" element={<OrderDeliveryDetailsComp />} />
        {/* ///////////////////////borrar al acabar//////////////////  */}
      </Routes>
    </BrowserRouter>
  );
};
