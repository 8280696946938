import React, { useState, useRef } from "react";
import styles from "../easyEncript/Encriptor.module.css";
import CryptoJS from "crypto-js";
import QRCode from "qrcode"; //npm install npm install qrcode
import { toPng } from "html-to-image"; //npm install html-to-image

const Encriptor = () => {
  const [claveSecreta, setClaveSecreta] = useState("clavePrivada");
  const [numero, setNumero] = useState("");
  const [numeroEncriptado, setNumeroEncriptado] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [copiado, setCopiado] = useState(false);
  const qrCodeImgRef = useRef(null); // Referencia específica para la imagen

  const encriptarYCopiar = () => {
    const valueToCopi = encriptar();
    setTimeout(() => {
      copiarPortapapeles(valueToCopi);
    }, 100);
  };

  const encriptar = () => {
    if (numero.length > 3) {
      console.log(numero);
      const encrypted = CryptoJS.RC4Drop.encrypt(
        numero.toString(),
        claveSecreta
      ).toString();
      setNumeroEncriptado(encrypted.substring(10));
      return encrypted.substring(10);
    }
  };

  const copiarPortapapeles = (valueToCopi) => {
    if (numero.length > 3 && valueToCopi.length > 0) {
      navigator.clipboard
        .writeText(valueToCopi)
        .then(() => {
          console.log("Valor copiado al portapapeles: ", valueToCopi);
          setCopiado(true);
          setTimeout(() => {
            setCopiado(false);
          }, 1000);
        })
        .catch((error) => {
          console.error("Error al copiar al portapapeles: ", error);
        });
    }
  };

  const generarQRCode = async () => {
    const valueToCopi = encriptar();
    try {
      const url = await QRCode.toDataURL(valueToCopi);
      setQrCodeUrl(url);
      setTimeout(() => {
        copiarQRCode();
      }, 500);
    } catch (err) {
      console.error(err);
    }
  };

  const copiarQRCode = async () => {
    if (qrCodeImgRef.current) {
      try {
        const dataUrl = await toPng(qrCodeImgRef.current);
        const blob = await (await fetch(dataUrl)).blob();
        const item = new ClipboardItem({ "image/png": blob });
        await navigator.clipboard.write([item]);
        setCopiado(true);
        setTimeout(() => {
          setCopiado(false);
        }, 1000);
      } catch (err) {
        console.error("Error al copiar al portapapeles: ", err);
      }
    }
  };

  return (
    <body>
      <div className={styles.mainContainer}>
        <div className={styles.encriptorContainer}>
          <span className={styles.title}>
            Genera números de referencia para los clientes
          </span>
          <div className={styles.inputContainer}>
            <input
              type="text"
              placeholder="Introduce la ficha de alquiler"
              className={styles.inputFields}
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
            />
            <input
              value={numeroEncriptado}
              type="text"
              placeholder="resultado"
              className={styles.inputFields}
              disabled
            />
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.interactiveButtons}
              onClick={encriptarYCopiar}
            >
              Generar y copiar código
            </button>

            <button
              className={styles.interactiveButtons}
              onClick={generarQRCode}
            >
              Generar y copiar código QR
            </button>
            {qrCodeUrl && (
              <div className={styles.qrGeneratedContainer}>
                <img ref={qrCodeImgRef} src={qrCodeUrl} alt="Código QR" />{" "}
                {/* Referencia específica para la imagen */}
                {/* <button
className={styles.interactiveButtons}
onClick={copiarQRCode}
>
Copiar QR al portapapeles
</button> */}
              </div>
            )}
            {copiado && <div className={styles.popUp}>Copiado con éxito</div>}
          </div>
        </div>
      </div>
    </body>
  );
};

export default Encriptor;
