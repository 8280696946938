import React, { useEffect, useState } from "react";
import { MainRouter } from "./router/MainRouter";
import "./App.css"; // Asegúrate de importar el CSS para los estilos

function App() {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const updateOrientation = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };

    // Bloquear la orientación a 'portrait'
    if (window.screen.orientation && window.screen.orientation.lock) {
      window.screen.orientation.lock("portrait").catch((err) => {
        console.error("Error locking screen orientation:", err);
      });
    } else {
      console.warn("Screen Orientation API is not supported by this browser.");
    }

    window.addEventListener("resize", updateOrientation);
    window.addEventListener("orientationchange", updateOrientation);

    return () => {
      window.removeEventListener("resize", updateOrientation);
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  return (
    <div className="App">
      {isPortrait ? (
        <MainRouter />
      ) : (
        <div className="landscape-warning">
          <p>Por favor, usa la aplicación en modo vertical.</p>
        </div>
      )}
    </div>
  );
}

export default App;
